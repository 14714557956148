import axios from 'axios';

// 'https://simple-control.liis.su/'; prod//
// 'https://dev.simple-control.liis.su/'; dev//
const getApiBaseUrl = () => {
  const { hostname } = window.location;

  return hostname === 'localhost' ? 'https://api-dev.simple-control.liis.su' : `https://api-${hostname}`;
};

const api = getApiBaseUrl();

const getAxiosRequest = () => axios.create({
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

export default {
  getRoomData(id) {
    const request = getAxiosRequest();
    return request.get(`${api}/rooms/${id}`);
  },
};
